import { useEffect, useState } from 'react'
import { Carousel } from '@matchplat/crono'

// styles
import styled from 'styled-components'

// translations
import { useTranslation } from 'react-i18next'

// images
import imageOne from '../img/Matchplat_Tab_01.png'
import imageTwo from '../img/Matchplat_Tab_02.png'
import imageThree from '../img/Matchplat_Tab_03.png'
import imageFour from '../img/Matchplat_Tab_04.png'

const ImagesContainer = styled.div`
    width: 50%;
    height: 100%;
    position: fixed;
    margin: 0;
    right: 0;
    top: 0;
`
/**
 * Displays of images
 * @component ImageCycle
 * @param {ImageCycleProps} props - The properties of the ImageCycle component
 * @returns {JSX.Element} The rendered ImageCycle component
 */
const ImageCycle = (): JSX.Element => {
  // translations
  const { i18n } = useTranslation(['common'])

  // local state
  const [selectedArrayOfImages, setSelectedArrayOfImages] = useState<string[]>([])

  useEffect(() => {
    const engArray = [imageFour, imageOne, imageTwo]
    const itaArray = [imageThree, imageOne, imageTwo]
    setSelectedArrayOfImages(i18n.language === 'en-US' ? engArray : itaArray)
  }, [i18n.language])

  return (
    <ImagesContainer>
      <Carousel elements={selectedArrayOfImages} isImage hideSteps imageStyle={{ height: '100vh', width: '100%' }} margin={false} slideTime={20000} />
    </ImagesContainer>
  )
}

export default ImageCycle
