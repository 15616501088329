// THIS COMPONENT WILL TEST IF A REBOUNCE IS NEEDED

import { useEffect, useContext } from 'react'
import {
  getRefreshToken,
  Spinner
} from '@matchplat/crono'

// router
import { useLocation } from 'react-router-dom'

// utils landing
import getLanding from './utils/getLanding'

// context
import { RouterContext } from './context/RouterContext'

const Index = ({ children }: { children: any }) => {
  // context
  const context = useContext(RouterContext)

  // local sate
  const location = useLocation()

  // check if the user is already logged
  useEffect(() => {
    const callUserDataForLogOrRebounce = () => {
      // this will be the redirect from main route form
      getRefreshToken({ manageRedirect: false, isSilent: false })
        .then(() => {
          // get landing params or redirect to explore, should be dashboard
          getLanding()
        })
        .catch(err => {
          // write full error if ENV is dev
          if (process.env.REACT_APP_URI_ENV === 'dev') {
            console.error(err)
          }
          // is still loading user and languages
          context.setIsLoading(false)
          // the user must not be rebounced this session
          context.setIsRebounce(false)
        })
    }

    // first try to rebounce, if not, I must not call getRefreshToken() again
    if (context.isRebounce) {
      callUserDataForLogOrRebounce()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return context.isRebounce
    ? (
      <Spinner />
      )
    : (
        children
      )
}

export default Index
