// graph
import { useMutation, gql } from '@apollo/client'

// graph
function useSharedMutations() {
  const [login, { loading: loginLoading }] = useMutation(
    LOGIN_USER
  )

  return {
    login,
    loginLoading
  }
}

// Mutation
export const LOGIN_USER = gql`
  mutation LogUser($email: String!, $password: String!, $rememberMe: Boolean!) {
    signIn(email: $email, password: $password, rememberMe: $rememberMe) {
      accessToken
      refreshToken
    }
  }
`

export default useSharedMutations
