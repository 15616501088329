import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import {
  Loading,
  uri
} from '@matchplat/crono'

// router
import Router from './Router'

// graph
import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache
} from '@apollo/client'

// import i18n
// remember to use SUSPENSE to load these files
import './i18n'

// USE THIS LINE TO CHANGE DATABASE URL
const apiUrl = ''

/**
 * Apollo Client configuration for GraphQL
 * @constant {ApolloClient} client - The configured Apollo Client instance
 */
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: new HttpLink({
    uri: apiUrl || uri.getUri(),
    credentials: 'include' // needed for cookies
  })
})

// THIS APPLICATION MUST USE A NEW APOLLO CONNECTION
// USE CRONO ONLY IF THE USER ALREADY EXISTS
// IN LOGIN USER DOES NOT EXISTS

ReactDOM.render(
  // need fallback to load translations
  <Suspense fallback={<Loading />}>
    <React.StrictMode>
      <ApolloProvider client={client}>
        <Router />
      </ApolloProvider>
    </React.StrictMode>
  </Suspense>,
  document.getElementById('root')
)
