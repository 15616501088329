/* eslint-disable react/no-children-prop */
import { Suspense } from 'react'
import { Box, PageLoading } from '@matchplat/crono'

// router
import { HashRouter as Router, Route, Routes } from 'react-router-dom'

// context
import { RouterProvider } from './context/RouterContext'

// forms
import TestBounce from './TestBounce'
import LoginForm from './forms/authentication/LoginForm'
import SignUpForm from './forms/authentication/SignUpForm'
import RequestReset from './forms/password/RequestReset'
import Reset from './forms/password/Reset'
import TopMenu from './menu/TopMenu'
import ImageCycle from './animations/ImageCycle'

/**
 * Render component for managing routes and rendering different forms
 * @component Render
 * @returns {JSX.Element} The rendered Render component
 */
const Render = (): JSX.Element => {
  return (
    <RouterProvider>
      <>
        <TopMenu />
        <Box style={{ minHeight: '100vh' }} alignItems="center">
          {/* FORMS LEFT SIDE */}
          {/* USING TEST BOUNCE FOR EVERY POSSIBLE ROUTE */}
          <Box width="50%" justifyContent="center" style={{ marginTop: '-40px' }}>
            <Router>
              <Suspense fallback={<PageLoading />}>
                <Routes>
                  <Route path="/" element={<TestBounce children={<LoginForm />} />} />
                  <Route path="/signup" element={<TestBounce children={<SignUpForm />} />} />
                  <Route path="/requestReset" element={<TestBounce children={<RequestReset />} />} />
                  <Route path="/resetToken" element={<TestBounce children={<Reset />} />} />
                </Routes>
              </Suspense>
            </Router>
          </Box>
          {/* IMAGE RIGHT SIDE */}
          <Box justify="stretch" align="stretch">
            <ImageCycle />
          </Box>
        </Box>
      </>
    </RouterProvider>

  )
}

export default Render
