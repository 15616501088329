import { useState } from 'react'
import { Button, Input, Typography, Box, Spacer, theme } from '@matchplat/crono'

// translations
import { useTranslation } from 'react-i18next'

// hooks
import { Resolver, useForm } from 'react-hook-form'

// graph
import { ApolloError, gql, useMutation } from '@apollo/client'

// router
import { useNavigate } from 'react-router-dom'

// types
import { RequestResetFormValuesProps, RequestResetVariablesProps } from '../formsProps'

/**
 * ChangePass component for initiating a password reset request
 * @component ChangePass
 * @returns {JSX.Element} The rendered ChangePass component
 */
const RequestReset = (): JSX.Element => {
  // router
  const navigate = useNavigate()

  // translations
  const { t } = useTranslation(['translations', 'common'])

  // local state
  const [isError, setIsError] = useState(false)
  const [isRequestDone, setIsRequestDone] = useState(false)
  const [mail, setMail] = useState('')

  // graph
  const onMutationCompleted = () => setIsRequestDone(true)

  const onMutationError = (error: ApolloError) => {
    console.error(error)
    setIsError(true)
  }

  const [requestPasswordReset, { loading: mutationLoading }] = useMutation(
    REQ_PASS_RESET,
    {
      onCompleted: onMutationCompleted,
      onError: onMutationError
    }
  )
  /**
  * Handles form submission for requesting a password reset
  * @async onSubmit
  * @param {RequestResetVariablesProps} variables - The form variables
  * @returns {Promise<void>}
  */
  const onSubmit = async (variables: RequestResetVariablesProps): Promise<void> => {
    setMail(variables.email)
    requestPasswordReset({ variables })
  }

  /**
   * Resolver function for form validation
   * @async resolver
   * @param {RequestResetFormValuesProps} values - The form values
   * @returns {Object} An object containing form values
   */
  const resolver: Resolver<RequestResetFormValuesProps> = async (values = {}) => {
    const errors: RequestResetFormValuesProps = {}
    !values.email && (errors.email = {
      type: 'required',
      message: t(
        'common:validation.required', { label: t('common:form.email') }
      )
    })

    return { values, errors }
  }

  const {
    formState: { errors },
    handleSubmit,
    register
  } = useForm<RequestResetFormValuesProps>({ resolver })

  return isRequestDone
    ? (
    <Typography size="subTitle">
      {t('translations:notes.checkEmail', { mail })}
    </Typography>
      )
    : (
    <Box column width="65%">
      {/* @ts-ignore */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          errors={errors}
          label={t('common:form.email')}
          placeholder="b2b@matchplat.com"
          type="email"
          hookFormRegister={{ ...register('email') }}
        />
        <Button
          noMargin
          isBlock
          textAlign='center'
          isFull
          color="secondary"
          text={t('common:button.send')}
          isLoading={mutationLoading}
          isError={isError}
          errorText={t('common:button.error')}
        />
      </form>
      <Spacer vertical />
      <Box column style={{ borderTop: theme.borderGreyColorDouble, paddingTop: theme.themeMarginSingle }}>
        <Typography size="subArticle" style={{ cursor: 'pointer' }} onClick={() => navigate('/')}>{t('actions.signIn')}</Typography>
      </Box>
    </Box>
      )
}

// mutation
const REQ_PASS_RESET = gql`
  mutation RequestPasswordReset($email: String!) {
    resetPasswordToken(email: $email) {
      success
    }
  }
`

export default RequestReset
