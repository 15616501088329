import { useContext } from 'react'
import { DropDown, MenuItem } from '@matchplat/crono'

// translations
import { useTranslation } from 'react-i18next'

// context
import { RouterContext } from '../context/RouterContext'

/**
 * TopMenu component for displaying language options
 * @component TopMenu
 * @returns {JSX.Element} The rendered TopMenu component
 */
const TopMenu = (): JSX.Element => {
  // context
  const context = useContext(RouterContext)

  // translations
  const { t, i18n } = useTranslation(['common'])

  // changes language
  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language)
  }

  return (
    <DropDown
      isLoading={context.isLoading}
      text={t('common:languages.language')}
      icon="language"
      labelSize="subArticle"
    >
      <MenuItem onClick={() => changeLanguage('it-IT')} labelSize="subArticle">
        italiano
      </MenuItem>
      <MenuItem onClick={() => changeLanguage('en-US')} labelSize="subArticle">
        english
      </MenuItem>
    </DropDown>
  )
}

export default TopMenu
