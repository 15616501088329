import { useState } from 'react'
import { Button, Input, Spacer, Typography, Box, theme, uri } from '@matchplat/crono'

// translations
import i18n from '../../i18n'

// hooks
import { Resolver, useForm } from 'react-hook-form'

// graph
import { gql, useMutation } from '@apollo/client'

// translations
import { useTranslation } from 'react-i18next'

// router
import { useNavigate, useLocation } from 'react-router-dom'

// utils
import useSharedMutations from '../../utils/useSharedMutations'

// types
import { AddUserVariablesProps, SignUpFormValuesProps } from '../formsProps'

const getParams = (search: string) => new URLSearchParams(search)

const validateEmail = (email: string) => {
  // eslint-disable-next-line
  return email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
}

/**
 * SignUpForm component for user registration
 * @component SignUpForm
 * @returns {JSX.Element} The rendered SignUpForm component
 */
const SignUpForm = (): JSX.Element => {
  // router
  const navigate = useNavigate()

  // translations
  const { t } = useTranslation(['translations', 'common'])

  // local state
  const [isError, setIsError] = useState(false)
  const location = useLocation()
  const params = getParams(location.search)

  const invitationEmail = params.get('invitationEmail')

  // shared  mutations
  const { login } = useSharedMutations()

  const onMutationError = (error: any) => {
    console.error(error)
    setIsError(true)
  }

  const [signUpFromInvitation, { loading: mutationLoading }] = useMutation(
    SIGN_UP_FROM_INVITATION_MUTATION,
    {
      onError: onMutationError
    }
  )

  /**
   * Resolver function for form validation
   * @function resolver
   * @param {SignUpFormValuesProps} values - The form values
   * @returns {Object} An object containing form values and errors
   */
  const resolver: Resolver<SignUpFormValuesProps> = async (values = {}) => {
    const errors: SignUpFormValuesProps = {}
    !values.email && (errors.email = {
      type: 'required',
      message: t(
        'common:validation.required', { label: t('common:form.email') }
      )
    })
    values.email && !validateEmail(values.email.toString()) && (errors.email = {
      type: 'required',
      message: t('common:validation.email_format_not_valid')
    })
    values.password !== values.matchPass && (errors.matchPass = {
      type: 'required',
      message: t('common:validation.matchPass')
    })
    !values.matchPass && !values.password && (errors.matchPass = {
      type: 'required',
      message: t(
        'common:validation.required', { label: t('common:form.passConfirm') }
      )
    })
    !values.name && (errors.name = {
      type: 'required',
      message: t(
        'common:validation.required', { label: t('common:form.name') }
      )
    })
    !values.password && (errors.password = {
      type: 'required',
      message: t(
        'common:validation.required', { label: t('common:form.password') }
      )
    })
    !values.surname && (errors.surname = {
      type: 'required',
      message: t(
        'common:validation.required', { label: t('common:form.surname') }
      )
    })
    return { values, errors }
  }

  const defaultValues = {
    language: i18n.language,
    phone: '',
    acceptedNewsletter: false,
    email: invitationEmail || ''
  }

  const {
    // control,
    formState: { errors },
    handleSubmit,
    register
  } = useForm<SignUpFormValuesProps>({ defaultValues, resolver })

  /**
   * Handles form submission
   * @async onSubmit
   * @param {AddUserVariablesProps} variables - The form variables
   * @returns {Promise<void>}
   */
  const onSubmit = async (variables: AddUserVariablesProps): Promise<void> => {
    signUpFromInvitation({
      variables,
      onCompleted() {
        login({
          variables: {
            email: variables.email,
            password: variables.password,
            rememberMe: false
          },
          onCompleted() {
            window.location.replace(uri.getAppUri('explore'))
          }
        })
      }
    })
  }

  return (
    <Box column width='65%'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Spacer vertical half />
        <Input
          errors={errors}
          label={t('common:form.name')}
          placeholder="Matchy"
          type="name"
          name="name"
          hookFormRegister={{ ...register('name') }}
        />
        <Input
          errors={errors}
          label={t('common:form.surname')}
          placeholder="TheBot"
          type="surname"
          name="surname"
          hookFormRegister={{ ...register('surname') }}
        />
        <Input
          errors={errors}
          label={t('common:form.email')}
          placeholder="b2b@matchplat.com"
          type="email"
          name="email"
          hookFormRegister={{ ...register('email') }}
          disabled={!!invitationEmail?.length}
        />
        <Input
          errors={errors}
          label={t('common:form.password')}
          placeholder="password"
          type="password"
          name="password"
          hookFormRegister={{ ...register('password') }}
        />
        <Input
          errors={errors}
          label={t('common:form.passConfirm')}
          placeholder="password"
          type="password"
          name="matchPass"
          hookFormRegister={{ ...register('matchPass') }}
        />
        <Input
          errors={errors}
          label={t('common:form.phone')}
          placeholder={t('common:form.phone')}
          name="phone"
          hookFormRegister={{ ...register('phone') }}
        />
        <Button
          color="secondary"
          isBlock
          isFull
          noMargin
          textAlign='center'
          text={t('common:button.signUp')}
          isLoading={mutationLoading}
          isError={isError}
          doneText={t('common:button.done')}
          errorText={t('common:button.error')}
        />
      </form>
      <Spacer vertical />
      <Box column style={{ borderTop: theme.borderGreyColorDouble, paddingTop: theme.themeMarginSingle }}>
        <Typography size="subArticle" style={{ cursor: 'pointer' }} onClick={() => navigate('/')}>{t('translations:signIn')}</Typography>
      </Box>
    </Box>
  )
}

// mutation
export const SIGN_UP_FROM_INVITATION_MUTATION = gql`
mutation SignUpByInvitation($email: String!, $name: String!, $surname: String!, $password: String!, $language: String!, $phone: String) {
  signUpByInvitation(email: $email, name: $name, surname: $surname, password: $password, language: $language, phone: $phone) {
    success
  }
}
`

export default SignUpForm
