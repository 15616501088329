import { useState } from 'react'
import {
  Button,
  Checkbox,
  Input,
  Spacer,
  Typography,
  Box
} from '@matchplat/crono'

// translations
import { useTranslation } from 'react-i18next'

// hooks
import { useForm, Controller, Resolver } from 'react-hook-form'

// graph
import { ServerError } from '@apollo/client'

// router
import { useNavigate } from 'react-router-dom'

// utils landing
import getLanding from '../../utils/getLanding'
import useSharedMutations from '../../utils/useSharedMutations'

// types
import { FormValuesProps } from '../formsProps'

/**
 * Login Form Component
 * @component LoginForm
 * @returns {JSX.Element} The rendered LoginForm component
 */
const LoginForm = (): JSX.Element => {
  // router
  const navigate = useNavigate()

  // local state
  const [isError, setIsError] = useState({ isError: false, message: '' })
  const [isShowingPassword, setIsShowingPassword] = useState(false)

  // translations
  const { t } = useTranslation(['translations', 'common'])

  // shared mutations
  const { login, loginLoading } = useSharedMutations()

  /**
   * Resolver function
   * @async resolver
   * @param {FormValues} values - Form values to be validated
   * @returns {Promise<Object>} Validation result
   */
  const resolver: Resolver<FormValuesProps> = async (values = {}) => {
    const errors: FormValuesProps = {}
    !values.email && (errors.email = {
      type: 'required',
      message: t(
        'common:validation.required', { label: t('common:form.email') }
      )
    })
    !values.password && (errors.password = {
      type: 'required',
      message: t(
        'common:validation.required', { label: t('common:form.password') }
      )
    })

    return { values, errors }
  }

  // remember me as default value
  const defaultValues = { rememberMe: false }

  const {
    control,
    formState: { errors },
    handleSubmit,
    register
  } = useForm<FormValuesProps>({ defaultValues, resolver })

  /**
   * Form submission handler
   * @async onSubmit
   * @function
   * @param {FormValues} variables - Form values to be submitted
   * @returns {Promise<void>}
   */
  const onSubmit = async (variables: FormValuesProps): Promise<void> => {
    setIsError({ isError: false, message: '' })
    login({
      variables,
      onCompleted: () => {
        getLanding()
      },
      onError: ({ networkError }) => {
        const serverError = networkError as ServerError
        if (serverError.response.status === 401) {
          setIsError({ isError: true, message: t('common:button.error_INVALID_CREDENTIALS') })
        } else {
          setIsError({ isError: true, message: t('common:button.error') })
        }
      }
    })
  }

  return (
    <Box column width='65%'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box column>
          {/* <Typography size="title" weight='bold'>
            {t('translations:titles.loginForm')}
          </Typography> */}
          <Typography size="title" weight='bold'>
            Login
          </Typography>
        </Box>
        <Spacer vertical />
        <Input
          errors={errors}
          label={t('common:form.email')}
          placeholder="b2b@matchplat.com"
          type="email"
          hookFormRegister={{ ...register('email') }}
        />
        <Input
          errors={errors}
          label={t('common:form.password')}
          placeholder="password"
          hookFormRegister={{ ...register('password') }}
          icon={{
            icon: isShowingPassword ? 'eyeSlash' : 'eye',
            iconPosition: 'right',
            onClickIcon: () => setIsShowingPassword(prev => !prev),
            isClickable: true
          }}
          iconPosition="right"
          type={isShowingPassword ? 'text' : 'password'}
        />
        <Controller
          control={control}
          name="rememberMe"
          render={({ field: { onChange, value, ref } }) => (
            <Checkbox
              {...register('rememberMe')}
              errors={errors?.rememberMe}
              value={value}
              onChange={onChange}
              label={t('common:form.remember')}
              ref={ref}
            />)
          }
        />
        <Button
          doneText={t('common:button.done')}
          errorText={isError.message}
          isError={isError.isError}
          isLoading={loginLoading}
          noMargin
          isBlock
          textAlign='center'
          color="secondary"
          isFull
          text={t('common:button.logIn')}
        />
        <Spacer vertical half />
        <Box justifyContent='flex-end'>
          <Typography
            color="secondary"
            decoration="underline"
            size="subArticle"
            style={{ cursor: 'pointer' }}
            onClick={() => navigate('/requestReset')} >{t('translations:actions.requestResetPassword')}
          </Typography>
        </Box>
      </form>
    </Box>
  )
}

export default LoginForm
