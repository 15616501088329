import { createContext, useState } from 'react'

// types
import { RouterStateProps } from './contextProps'

/**
 * Router State
 * @type RouterState
 * @param {boolean} isLoading - Indicates whether the router is in a loading state
 * @param {boolean} isRebounce - Indicates whether the user needs to be rebounced
 * @param {function} setIsLoading - Function to set the loading state
 * @param {function} setIsRebounce - Function to set the rebounce state
 */
const initialState: RouterStateProps = {
  isLoading: true,
  isRebounce: false,
  setIsLoading: () => undefined,
  setIsRebounce: () => undefined
}

/**
 * Router Context
 * @type RouterStateProps
 */
export const RouterContext = createContext(initialState)

/**
 * Router Provider Component
 * @component RouterProvider
 * @param {Object} props - Props for the RouterProvider component
 * @param {JSX.Element} props.children - The child elements to be wrapped by the provider
 * @returns {JSX.Element} The rendered RouterProvider component
 */
export const RouterProvider = ({ children }: { children: JSX.Element }) => {
  // loading for the topMenu languages
  const [isLoading, setIsLoading] = useState<boolean>(true)
  // test if the user must be rebounced or not
  const [isRebounce, setIsRebounce] = useState<boolean>(true)

  // values to send through context
  const values = {
    isLoading,
    setIsLoading,
    isRebounce,
    setIsRebounce
  }

  return <RouterContext.Provider value={values}>{children}</RouterContext.Provider>
}
