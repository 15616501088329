import { uri } from '@matchplat/crono'

/**
 * Get the landing param from the querystring
 * @component getLanding
 * @returns {string | null} replace the window location with the landing param
 */
const getLanding = (): string | null => {
  const params = new URLSearchParams(window.location.hash)
  const landing = params.get('#/?landing')
  window.location.replace(landing ? new URL(landing) : uri.getAppUri('explore'))
  return landing
}

export default getLanding
